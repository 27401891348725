import React, { useEffect } from 'react';
import Img from 'gatsby-image';

const GatsbyImage = ({
  image,
  title,
  minHeight,
  className,
  fixedImage,
  alt,
}) => {
  return (
    <Img
      className={`gatsby-image ${className}`}
      fluid={image}
      alt={alt ?? title}
      style={{ minHeight: `${minHeight}px` }}
      fixed={fixedImage}
    />
  );
};

export default GatsbyImage;
