import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/youtube';
import styles from './youtubePlayer.module.scss';

const YouTubePlayer = ({ url, controls, className }) => (
  <div className={`${styles.video} ${className}`}>
    <div>
      <ReactPlayer
        className="iframe"
        url={url}
        controls={controls}
        width="100%"
        height="100%"
      />
    </div>
  </div>
);

YouTubePlayer.propTypes = {
  url: PropTypes.string,
};

YouTubePlayer.defaultProps = {
  url: 'https://www.youtube.com/embed/DZMcaO1AtGg',
  controls: false,
  className: '',
};

export default YouTubePlayer;
